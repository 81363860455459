<template>
  <div class="card">
    <div class="card-header">
      <strong>
        Michelle Zamora - Junior Operations Manager at eFlexervices :
      </strong>
    </div>
    <div class="card-body">
      <div class="author-image">
        <img
          src="/assets/images/author/michelle.png"
          alt="Image"
          class="rounded-circle" />
      </div>
      <p>
        <i>
          With over five years of experience at eFlexervices, Michelle Zamora currently serves as Junior Operations Manager, where she excels in optimizing team performance and driving operational efficiency. Since joining the company, Michelle has held various roles, including Project Manager and Customer Support Team Leader, gaining expertise in project management, team coaching, client management, and workflow improvement. Known for her commitment to clear communication and fostering strong client relationships, Michelle continues to play a pivotal role in enhancing the productivity and success of eFlexervices' outsourcing partnerships.
        </i>
      </p>
    </div>
  </div>
  <!-- Post Single - Author End -->
</template>

<script>
  export default {
    data() {
      return {}
    },
  }
</script>
